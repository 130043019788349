export async function TabSelect(
    props: { kedo: { api: () => any } },
    defDossierId: any,
    dossiers: any[]
) {
    const api = props.kedo.api()
    const displayPosition = await Promise.all(
        dossiers.map((dossier) => {
            const baseUrl = api.getDisplayPositionEndpoint()
            const url = `${baseUrl}?defDossier=${defDossierId}&limit=100&contentDossierId=${dossier}`
            return api.get(url)
        })
    )

    const displayItems = displayPosition.flatMap((response) => {
        return response.data.results
            .filter((result: { type: string }) => result.type === 'tab')
            .map((result: { display_items: any }) => result.display_items)
    })

    const displayPositionIds = displayItems.flatMap((innerArray) =>
        innerArray.map(
            (item: { display_position_id: any }) => item.display_position_id
        )
    )

    if (displayPositionIds.length > 0) {
        const tabContent = await Promise.all(
            dossiers.map((dossier) => {
                const baseUrl = api.getContentDossierEndpoint(dossier)
                const url = `${baseUrl}?displayPosition=${displayPositionIds[0]}`
                return api.get(url)
            })
        )

        const combinedItems: Record<
            number,
            { name: string; id: number; content: any }[]
        > = {}
        const combinedLinks: Record<
            number,
            {
                name: string
                link_id?: number
                content: any
                multiple: boolean
            }[]
        > = {}

        tabContent.forEach((response, index) => {
            const dossierItems = []
            const dossierLinks = []

            response.data.items.forEach((item: any) => {
                const contentId = item.id
                const content = response.data.content[contentId]
                if (item.def_dossier_def_field?.name) {
                    dossierItems.push({
                        name: item.def_dossier_def_field?.name || '',
                        id: item?.id || '',
                        content: content || '',
                    })
                } else if (item.def_dossier_link?.name) {
                    dossierLinks.push({
                        name: item.def_dossier_link?.name || '',
                        link_id: item?.id || '',
                        content: content || '',
                        multiple:
                            item.def_dossier_link?.settings?.multiple || false,
                    })
                }
            })

            const dossierId = response.data.dossier.id

            if (!combinedItems[dossierId]) {
                combinedItems[dossierId] = []
            }
            combinedItems[dossierId].push(...dossierItems)

            if (!combinedLinks[dossierId]) {
                combinedLinks[dossierId] = []
            }
            combinedLinks[dossierId].push(...dossierLinks)
        })

        const contentValue = Object.keys(tabContent[0].data.content)

        return { combinedItems, combinedLinks, contentValue }
    }
    return { combinedItems: {}, combinedLinks: {} }
}
